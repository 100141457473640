
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

b{
  text-transform:none;
}

h1{
  font-size: 30px !important;
  font-weight: bolder;
  font-family: 'Raleway', sans-serif;
  text-transform: none;
  color:  #279989 ;
  text-align: center;
  margin-bottom: 3rem !important;
}

section{
  padding-bottom: 6rem;
  padding-top: 6rem;
}

table{
  margin-left: auto;
  margin-right: auto;
}

.seccao2{
  background-color: #EFEDF1;
}

.seccao3{
  padding-top: 0;
  background-color: #EFEDF1;
}

.seccao4{
  background-color: #279989;
}

.seccao4 p{
  color: white;
  font-size: 15px;
  margin-bottom: 0 !important;
  line-height: 1.5rem;
  text-align: center;
}

.padtop{
  padding-top: 1rem;
}

.padbot{
  padding-bottom: 1rem;
}

.texto{
  margin:auto;
  width: 70%;
}

.texto p, .texto2 p{
  font-size: 17px;
}

.card{
  width: 60%;
}

.card-body{
  text-align: center;
  font-size: 13px;
}

.card-text{
  line-height: 25px;
}

h2{
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: 'Raleway', sans-serif;
  text-transform: none;
  color:  #54585A;
  text-align: left;
  margin-bottom: 2rem !important;
  padding-top: 1.5rem;
}

.texto2{
  margin:auto;
  width: 85%;
}

li{
  font-size: 17px;
  text-transform: none;
  color: #54585A;
  line-height: 2rem;
}

.seccao2 li{
  
  padding-bottom: 1rem;
}

.seccao2 a, .seccao1 a{
  text-transform: none;
  color: #279989;
}

.imagemsec{
  width: 50%;
  margin-top: 2rem;
}

.ptop{
  padding-top: 6rem;
}

.img1{
  width: 50%;
  margin-top: 2rem;
}

.img2{
  width: 40%;
  margin-top: 2rem;
}

.img3{
  padding-top: 4rem;
  width: 50%;
}

.imgConsorcio{
  width: 50%;
  padding-top: 0 !important;
}

.imgConsorcio2{
  width: 38%;
  padding-top: 1rem;
}

.consorcio{
  margin-bottom: 6rem;
}

.grelha{
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  width: 85%;
  margin: auto;
  justify-content: center;
  justify-items: center;
}

.row1{
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 14rem 14rem 14rem 14rem;
  justify-content: center;

}

.row2{
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 14rem 14rem 14rem;
  justify-content: center;
}

.imgrow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;  
}

.imgrow img{
  width: 90%;
  margin: auto;
}


.cartao {
  display: grid;
  background-color: white;
  text-align: center;
  align-items: center;
  flex-grow: 1;
  max-width: 20%;
  min-height: 9rem;
  border-radius: 0.5rem;
  box-shadow: 10px 10px 22px -8px rgb(163 163 163);
  z-index: 1000;
  padding: 10px;
}

.cartao span {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0;
  padding: 0 1rem 0 1rem;
 width: 100%;
 min-width: 25%;
 text-transform: none;
}

.triangulo{
  width: 40%;
  height: 120%;
  position: absolute;
  margin-bottom: 0;
  bottom: 0;
  right: 0;
}


.relativa{
  position: relative;
}




@media only screen and (max-width: 786px){
  .cartao{
    max-width: 30%;
  };


  .imagemsec{
    width: 70%;
    margin-top: 2rem;
  }

  .img1{
    width: 70%;
  }
  
  .img2{
    width: 60%;
  }
  
  .img3{
    padding-top: 4rem;
    width: 70%;
  }
}

@media only screen and (max-width: 450px){
  .cartao{
    max-width: 50%;
  }
  
  
  .imagemsec{
    width: 90%;
    margin-top: 2rem;
  }

  .img1{
    width: 100%;
  }
  
  .img2{
    width: 100%;
  }
  
  .img3{
    padding-top: 4rem;
    width: 100%;
  }
}
