td{
    text-transform: none;
    font-family: 'Lato';
    color: #54585A;
    font-size: 14px;
}
.negrito{
    font-weight: bold;
    padding-right: 5rem !important;

}

tbody{
    display: inline-block;
    margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  text-align: left;
}

.centro2{
    text-align: center;
}

.seccao1{
    background-color: white !important;
}