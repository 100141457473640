*{
    /*font-family: 'Lato'; */
    font-family:'Raleway', sans-serif;
}

p {
  text-transform: none;
  font-size: larger;
  line-height: 2rem;
  width: 100%;
  color: #54585A;  
  margin-bottom: 8px !important;
  text-align: justify;
  
}

.justify{
  text-align: justify;
}

.intro{
  width: 44%;
  
}

.introbtn{
  width: 100%;
}


#btn-saiba-mais{
  background-color: #279989;
  text-transform:uppercase;
  margin-top: 1rem;
  padding: 1% 2% 1% 2%;
  border-width: 0;
  border-radius: 50px;
  letter-spacing: 2px;
  font-size: 15px;
}

img{
display: block;
margin: auto;
}

.centro{
  position: relative;
  background-color: #EFEDF1;
  height:auto;
  padding-top: 7rem;
}

.centrar{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}

.ipet{
  width: 50%;
  margin-bottom: 0;
  margin-right: 0;
  padding-top: 3rem;
  bottom: 0;
  right: 0;
}

.ipet2{
  display: none;
}

@media only screen and (max-width: 991px){
  .centro{
    height:52rem;
  }

  .intro{
    width: 100%;
    margin: auto;
    margin-top: 3rem;
  }


  .ipet{
    visibility: hidden;
  }

  .ipet2{
    padding-top: 1rem;
    display: block;
  }


}

@media only screen and (max-width: 767px){

.centro{
  height: 50rem;
}

#btn-saiba-mais{
  background-color: #279989;
  text-transform:uppercase;
  margin-top: 1rem;
  padding: 2% 3% 2% 3%;
  border-width: 0;
  border-radius: 50px;
  letter-spacing: 2px;
  font-size: 13px;
}

.ipet2{
  padding-top: 2.5rem;
}
}

@media only screen and (max-width: 586px){

  .introbtn{
    width: 75%;
    margin: auto;
  }

  .centro{
    height: 50rem;
  }

  .header .logo{
    width: 25%;
  }
}

@media only screen and (max-width: 397px){

  .introbtn{
    width: 85%;
    margin: auto;
  }

  .ipet2{
    margin-top: 0.5rem;
  }

  
  .header .logo{
    width: 30%;
  }
}

@media only screen and (max-width: 360px){

  .introbtn{
    width: 75%;
    margin: auto;
  }

  .centro{
    height: 55rem;
  }
  }

@media only screen and (max-width: 319px){

  .introbtn{
    width: 100%;
    margin: auto;
  }

  }